<template>
<v-card>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <DatePicker label="Date" :objectForm="objEvent" fieldName='eve_date_start'></DatePicker>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <DatePicker label="Date" :objectForm="objEvent" fieldName='eve_date_end'></DatePicker>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Objet" prepend-icon="mdi-script-text-outline" placeholder="Titre" v-model="objEvent.eve_title"></v-text-field>
        </v-flex>
        <!-- <v-flex xs3 class="pa-2"><v-text-field label="Code" prepend-icon="mdi-script-text-outline" placeholder="Code" v-model="objEvent.eve_code"></v-text-field></v-flex> -->
        <v-flex xs6 class="pa-2">
            <v-select :items="types" item-text="typ_name" item-value="typ_id" prepend-icon="mdi-account-wrench" v-model="objEvent.eve_typ_id" label="Type" single-line >   
            </v-select>
        </v-flex>
        <!-- <v-flex xs6 class="pa-2"><v-text-field label="Valeur" v-model="objEvent.eve_value"></v-text-field></v-flex>
        <v-flex xs3 class="pa-2"><v-text-field label="Cout" v-model="objEvent.eve_cost_value"></v-text-field></v-flex>
        <v-flex xs3 class="pa-2"><v-checkbox :true-value="1" :false-value="0" v-model="objEvent.eve_delegation" label="Sous traité"></v-checkbox></v-flex> -->
        <v-flex xs6 class="pa-2">
            <AutoCompMulti v-if="!assocItem" :title="'Personnel'" ressource="users" :listObject.sync="autoComplete.user" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.user" :fields="{ index: 'usr_id', prefetch: true, preprendIcon: 'mdi-account-multiple-outline', text:'usr_firstName,usr_lastName', search:'usr_middlename', multiSelect:true }"
                class="ml-2 mr-2">
            </AutoCompMulti>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <AutoCompMulti v-if="!assocItem" :title="'Materiel'" ressource="equipments" :listObject.sync="autoComplete.equipment" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.equipment" :fields="{ index: 'equ_id', prefetch: true, preprendIcon: 'mdi-tools', text:'equ_name', search:'equ_name', multiSelect:true }"
                class="ml-2 mr-2">
            </AutoCompMulti>
        </v-flex>
        <!-- <v-flex xs12 class="pa-2">
            <AutoCompMulti v-if="!assocItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.contact" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.contact" :fields="{ index: 'ctt_id', preprendIcon: 'mdi-tools', text:'ctt_middlename', search:'ctt_middlename', multiSelect:true }"
                class="ml-2 mr-2">
            </AutoCompMulti>
        </v-flex> -->
        <v-flex xs12 class="pa-2">
            <v-textarea rows="2" auto-grow prepend-icon="mdi-comment" label="Description" v-model="objEvent.eve_custom_fields.eve_description"></v-textarea>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-subheader class="pl-0">Résolution / avancement</v-subheader>
            <v-slider class="mx-3"
            v-model="objEvent.eve_resolve_percent" :step="10"
            thumb-label="always"
            ></v-slider>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="objEvent.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteEvent()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded  
            color="success" 
            :loading="saveProgress" @click.native="saveEvent()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn rounded  
            color="orange" 
            :loading="saveProgress" @click.native="saveEvent('GEN03')" :disabled="saveProgress">
            Réalisé
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('modalEvent', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { eveObj } from '../mixins/eve_obj.js'
import { cacheGetters } from "../store/cache.module"
import AutoCompMulti    from './Autocomplete_multi.vue'
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'eventedit',
    props: ['toast', 'xxxId', 'eveId', 'eveTable', 'eveType', 'switchModal', 'cooridnates', 'start'],
    components: { AutoCompMulti, DatePicker },
    mixins: [ eveObj ],
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            states : [],
            types : [],
            dateEve: { start: false, end: false },
            optionMenu: false,
            eventType: {
                'act' : [ 'ACT' ],
                //'inv' : [ 'INE' ],
            },
            autoComplete: {
                user     : [],
                contact  : [],
                equipment: [],
            },
            autoCompleteSource:{
                user     : [],
                contact  : [],
                equipment: [],
            },
            //assocItem: false
        }
    },
    computed: {
        assocItem(){
            if( Object.prototype.toString.call( this.xxxId ) === '[object Array]' ) {
                return true
            } else {
                return false
            }
            //return (this.objEvent.eve_id > 0) ? false : true
        },
    },
    //beforeMount: function(){ },
    mounted:function (){
        if ( this.eveType ){
            this.types = this.types.concat( cacheGetters.getTypes(this.eveType) )
        } else if( this.eventType[this.eveTable] !== undefined ){
            this.eventType[this.eveTable].forEach(element => {
                this.types = this.types.concat( cacheGetters.getTypes(element) )
            });
        } else {
            this.types = this.types.concat( cacheGetters.getTypes('ACT') )
        }
        if( this.eveId !== undefined && this.eveId > 0 ){
            this.objEvent.eve_id = this.eveId
            this.getEventShow('?include=user,contact,equipment').then( () => {
                for( let prop in this.autoCompleteSource ){
                    if( this.objEvent[prop] ){
                        this.objEvent[prop].data.forEach(element => {
                            this.autoCompleteSource[prop].push(element)
                            if(element.usr_id){ this.autoComplete[prop].push(element.usr_id) }
                            if(element.equ_id){ this.autoComplete[prop].push(element.equ_id) }
                            if(element.ctt_id){ this.autoComplete[prop].push(element.ctt_id) }
                        });
                    }
                }
            })
        } else {
            this.cleanObjEvent()
            this.objEvent.eve_id         = 0
            this.objEvent.eve_typ_id     = this.types[0].typ_id
            this.objEvent.eve_table_id   = this.eveTable
            this.objEvent.eve_xxx_id     = this.xxxId
            this.objEvent.eve_date_start = this.locDateFormat.getToday('en')
            if( this.start ){
                this.objEvent.eve_date_start = this.start
            }
        }
        if( this.cooridnates && this.cooridnates.lat && this.cooridnates.lng ){
            this.objEvent.eve_lat = this.cooridnates.lat
            this.objEvent.eve_lng = this.cooridnates.lng
        }
    },
    methods: {
        saveEvent(state){
            state = state || 'GEN01'
            this.objEvent.eve_sta_id = state
            this.objEvent._data.user       = this.autoComplete.user
            this.objEvent._data.contact    = this.autoComplete.contact
            this.objEvent._data.equipment  = this.autoComplete.equipment
            if( state == 'GEN03' ){
                this.objEvent.eve_resolve_percent = 100
            }
            if( this.objEvent.eve_id !== undefined && this.objEvent.eve_id > 0 ){
                this.putEventUpdate().then( () => {
                    this.switchModal('modalEvent', 0, true)
                })
            } else {
                this.postEventStore().then( () => {
                    this.toast({ text: 'Fiche enregistrée !' })
                    this.switchModal('modalEvent', 0, true)
                })
            }
        },
        deleteEvent(){
            this.deleteObjEvent().then( () => {
                this.switchModal('modalEvent', 0, true)
            })
        }
    }

}
</script>