<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="elevation-1" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Versions</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn outlined rounded small color="green" @click="editLayer()"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.prv_created_at`]="{ item }">
            <v-chip label outlined>{{ locDateFormat.toLocale( item.prv_created_at ) }}</v-chip>
        </template>
        <template v-slot:[`item.prv_typ_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheType('VER', item.prv_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.prv_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.prv_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.prv_updated_at`]="{ item }">
            <v-icon left color="green" @click="prv_id = item.prv_id; openAssocModal(item.prv_id)">mdi-layers-triple</v-icon>
        </template>
        <template v-slot:[`item.prv_id`]="{ item }">
            <v-icon left color="orange" @click="editLayer(item.prv_id)">mdi-pencil</v-icon>
            <v-icon v-show="item.prv_sta_id == 'GEN01'" left @click="deleteLayer(item.prv_id)">mdi-delete</v-icon>
            <v-icon v-show="item.prv_sta_id == 'GEN05'" left >mdi-play-box</v-icon>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getEvent )" color="error" icon="mdi-alert">
            Aucune version de layer
        </v-alert>
        </template>
    </v-data-table>
    <!-- Ajout d'une version -->
    <v-dialog v-model="modalAddLayer" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Edition version {{ (item.prv_id) ? '#' + item.prv_id : '(nouveau)' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalAddLayer = !modalAddLayer"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <v-layout row wrap>
            <v-flex xs3 class="pa-2">
                <v-text-field label="Version" v-model="item.prv_version"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-select 
                    :items="getCacheType('VER')" item-text="typ_name" item-value="typ_id" 
                    v-model="item.prv_typ_id" label="Type de calque" >
                </v-select>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-select 
                    :items="getCacheState('GEN')" item-text="sta_label" item-value="sta_id" 
                    v-model="item.prv_sta_id" label="Statut" >
                </v-select>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-select 
                    :items="getCacheType('PVS')" item-text="typ_name" item-value="typ_id" 
                    v-model="item.prv_typ_id_storage" label="Type de stockage" >
                </v-select>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Commentaire" v-model="item.prv_description"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-file-input @change="getFileDocument" ref="fileinput" v-model="item.obj_file" show-size label="Fichier"></v-file-input>
            </v-flex>
            <v-flex xs3 class="pa-2">
                {{item.prv_lra_id_default}}
            </v-flex>
            <v-flex xs3 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id" :items="getCacheType('PRJ')" v-model="item.prv_typ_id_projection"
                color="grey lighten-5" label="Projection">
            </v-select>
            </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn outlined color="orange" @click.native="postDocuments()" :loading="loading.uploadBtn" :disabled="loading.uploadBtn">maj doc</v-btn>
            <v-btn outlined color="orange" :loading="loading.uploadBtn" :disabled="loading.uploadBtn">maj listes</v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded color="success" @click.native="saveVersion()" :loading="loading.uploadBtn" :disabled="loading.uploadBtn">Enregistrer</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Gestion des layers -->
    <v-dialog v-model="modalLayer" max-width="900" scrollable style="z-index:1056;">
        <tab-layer v-if="modalLayer" :objProject="objProject" :prv_id="prv_id" :toast="toast" :switchModal="switchModal"></tab-layer>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import layerTab  from '../components/Tab_layerassoc.vue'
import { cacheGetters, cacheData } from "../store/cache.module"

export default {
    name: 'tab_version',
    props: ['toast', 'objProject'],
    data: function () {
      return {
            loading: { uploadBtn: false, rebuildBtn: false, getVersion: false },         // Enregistrement en cours...
            locDateFormat: dateLocale,
            enableAdd: true,
            stateColor: {},
            pagination: {}, pagin: { total: 1, currentPage: 1, limit: 0, perPage: 20, totalPage: 1 },
            headers: [
            { text: 'Fichier', align: 'left', value: 'prv_filename'},
            { text: 'Version', align: 'left', value: 'prv_version'},
            { text: 'Dépot',   align: 'left', value: 'prv_created_at'},
            { text: 'Statut',  align: 'right', value: 'prv_sta_id' },
            { text: 'Type',    align: 'right', value: 'prv_typ_id', sortable: false },
            { text: 'Calques',  align: 'right', value: 'prv_updated_at', sortable: false },
            { text: 'Action',  align: 'right', value: 'prv_id', sortable: false },
            ],
            items  : [],
            item   : {},
            addItem: {
                prv_id: 0, prv_pro_id: this.pro_id, prv_sta_id: 'GEN01', prv_typ_id: 'VER01', prv_version: '', prv_filename: '', 
                prv_geojson: '', prv_description: 'vcvx', prv_lra_id_default: null, prv_typ_id_storage: 'PVS01',
                prv_usr_id_creat: 0, prv_created_at: null, prv_updated_at: null, prv_typ_id_projection: this.objProject.pro_typ_id_projection,
                obj_file : null
            },
            modalLayer : false,
            modalAddLayer : false,
            verTab: {},
            prv_id: 0
      }
    },
    computed: {
        user(){
            return cacheData.user
        },
    },
    beforeMount: function (){
        for (var prop in this.addItem) {
            this.item[prop] = this.addItem[prop]
        }
    },
    mounted: function (){
        this.getVersion()
    },
    methods: {
        getVersion(){
            this.loading.getVersion = true
            this.$http.get( '/projectversions' )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getVersion = false
            })
        },
        editLayer(prv_id) {
            prv_id = prv_id || 0
            if( prv_id ){
                this.$http.get( '/projectversions/' + prv_id ).then( (response) => {
                    for (let prop in response.data.data) {
                        this.item[prop] = response.data.data[prop]
                    }
                    this.modalAddLayer = true
                })
            } else {
                for (let prop in this.addItem) {
                    this.item[prop] = this.addItem[prop]
                }
                this.item.prv_usr_id_creat = this.user.usr_id
                this.modalAddLayer = true
            }
        },
        deleteLayer(prv_id){
            this.$http.put( '/projectversions/' + prv_id, { prv_id: prv_id, prv_sta_id: 'GEN05' } ).then( () => {
                this.getVersion()
                this.toast({ color: 'success', text: 'Calque supprimée !' })
            })  
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        saveVersion(){
            this.loading.uploadBtn = true
            var formFile = {}
            formFile = new FormData()
            for (let prop in this.item) {
                formFile.append(prop, this.item[prop])
            }
            if( this.item.obj_file ) {
                formFile.append('version_file', this.item.obj_file, this.item.obj_file.name)
            }
            let route = '/projectversions/'
            if( this.item.prv_id > 0 ){
                route = '/projectversions/' + this.item.prv_id
            }
            this.$http.post( route, formFile )
            .then( () => {
                //this.getItemFile()
                this.getVersion()
                this.enableAdd         = true
                this.loading.uploadBtn = false
                this.modalAddLayer     = false
                this.toast({ text: 'Fichier enregistré !' })
            })
        },
        rebuildGeojson(id){
            this.loading.rebuildBtn = true
            this.$http.put( '/projectversions/' + id + '/rebuild' )
            .then( () => {
                this.toast({ text: 'Fichier reconstruit !' })
                this.loading.rebuildBtn = false
            })
        },
        rebuildLayer(id){
            this.loading.rebuildBtn = true
            //axios.post( '/', { _method:'GET', _path: 'geojson/' + id + '/project/' + this.objProject.pro_id + '/layerassoc', projection:'', pro_id: this.objProject.pro_id, prv_id: id } )
            this.$http.put( '/projectversions/' + id + '/rebuild' ).then( () => {
                this.toast({ text: 'Recalcul des calques terminé !' })
                this.loading.rebuildBtn = false
            })
        },
        switchModal(name, mode){
            name    = name || 'modalLayer'
            mode    = mode || 0
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
        },
        downloadDxf(docid){ // Telechargement du shape ou dxf
            this.$http.get( '/', {
                params: { prv_id: docid, _path:'dxfwrite/' + docid, prv_pro_id: this.objProject.pro_id },
                responseType:"blob"
            })
            .then( (response) => {
                this.saveProgress = false
                this.$download(response.data, response.headers['content-name'], response.headers['content-type'])
            })
        },
        getFileDocument(file){
            if( this.addItem.prv_filename.length == 0 ){
                let nameTab = file.name.split(".")
                this.addItem.prv_filename = nameTab[0]
            }
            //this.addItem.filename = file.name
        },
        postDocuments(){
            this.saveProgress = true
            //axios.post( '/', { _method:'GET', _path: 'geojson/' + id + '/project/' + this.objProject.pro_id + '/layerassoc', projection:'', pro_id: this.objProject.pro_id, prv_id: id } )
            this.$http.post( '/trees/documents/', {} ).then( () => {
                this.toast({ text: 'Recalcul des calques terminé !' })
                this.saveProgress = false
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'tab-layer'   : layerTab
    }
}
</script>