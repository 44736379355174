var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"barrinfo"}},[_c('v-toolbar-title',[_vm._v("Planning")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","outlined":"","color":"grey darken-2"},on:{"click":function($event){_vm.focus = ''}}},[_vm._v("Aujourd'hui")]),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_vm._l((_vm.types),function(item,index){return [_c('v-list-item',{key:index,on:{"click":function($event){_vm.type = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]})],2)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":"","color":"grey darken-2"},on:{"click":function($event){return this.getEvents()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card',{staticClass:"ma-4 mb-2"},[_c('v-sheet',{staticClass:"d-flex",attrs:{"tile":"","height":"54"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$refs.calendar.title))]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"change":_vm.getEvents,"click:event":_vm.showEvent,"click:date":_vm.createEvent,"click:more":_vm.showMoreEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('CalendarEvent',{attrs:{"event":event}})]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1),_c('v-dialog',{staticStyle:{"z-index":"2066"},attrs:{"max-width":"900"},model:{value:(_vm.modalEvent),callback:function ($$v) {_vm.modalEvent=$$v},expression:"modalEvent"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectionEvent.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){return _vm.switchModal('modalEvent', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-2"},[(_vm.modalEvent)?_c('EditEvent',{staticClass:"elevation-2",attrs:{"toast":_vm.toast,"switchModal":_vm.switchModal,"xxxId":_vm.selectionEvent.xxxId,"eveId":_vm.selectionEvent.eveId,"eveTable":_vm.selectionEvent.eveTable,"eveType":_vm.selectionEvent.eveType,"title":_vm.selectionEvent.title,"cooridnates":_vm.selectionEvent.cooridnates,"start":_vm.selectionEvent.start}}):_vm._e()],1)],1)],1),_c('v-dialog',{staticStyle:{"z-index":"2066"},attrs:{"max-width":"900"},model:{value:(_vm.modalListEvent),callback:function ($$v) {_vm.modalListEvent=$$v},expression:"modalListEvent"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }