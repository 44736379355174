<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export des arbres</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-menu ref="datebyevent" v-model="dateMenu.date_byevent"
                        :return-value.sync="dateReport.date_byevent"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_byevent, 'fr' )"
                                label="Date de référence de l'extraction" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_byevent">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_byevent = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.datebyevent.save( dateReport.date_byevent )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs12>
                    <v-select 
                        item-value="ctp_id" item-text="ctp_label"
                        v-model="selectedFields" :items="fields" label="Champs" multiple>
                    </v-select>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem" @click="downloadReport('trees', {})">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export shape</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem" @click="downloadReport('trees/shape', {})">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }  from '../mixins/equ_obj.js'

export default {
    name: 'site_report',
    props: ['toast'], 
    mixins: [ equObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            dateMenu: { 
                date_byevent   : null,
            },
            dateReport : {
                date_byevent   : null,
            },
            items  : [],
            select : [],
            fields : [],
            selectedFields : [],
            version: cacheGetters.getVersion(),
            state  : cacheGetters.getTypesByTag('EVE', 'PRO'),
        }
    },
    mounted:function (){
        let version = this.version.find(obj => obj.prv_typ_id === 'VER04')
        if( version ){
            this.$http.get( '/layersassoc/?per_page=false&lra_prv_id=' + version.prv_id + '&extended=withtemplate' )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.fields = response.data.data
                }
            })
        }

    },
    methods: {
        downloadReport(report, option){
            this.loading.getItem = true
            let searchCriteria = this.getEquipmentFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: 'get', url: '/report/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                let filename = "";
                let disposition = response?.headers['content-disposition'];
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g, '');
                    }
                } else {
                    filename = 'rapport.xlsx'
                }
                this.$download(response.data, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadSite(){
            this.loading.getItem = true
            let searchCriteria = this.getEquipmentFilter()
            this.$http.customRequest({ method: 'get', url: '/report/tree/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
    },
    components: { }
}
</script>