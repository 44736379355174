import Vue from "vue";
import Storages      from 'js-storage'

export const cacheData = Vue.observable({
  user      : {},
  states    : {},
  types     : {},
  filter    : {},
  searchBar : {},
  bookmark  : {},
  taskType  : [],

  projects  : [],
  objectType: [],
  theme     : [],
  version   : [],
  lists     : [],
  listsData : [],
  //--------------------------
  filter_table_id: ['dxo', 'pro'],
  filter_sta_id  : ['GEN01', 'GEN02'],
  filter_eve_id  : ['EVE01', 'EVE02'],
  filter_layer   : {},
  filter_version : {},
  pro_projection: '',
})

export const cacheGetters = {
  getStates: (group, id) => {
    if( group !== undefined && cacheData.states[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.states[group][id] !== undefined ) ? cacheData.states[group][id] : { typ_label: 'Non définit' };
      } else {
        let stateType = []
        for( let prop in cacheData.states[group] ){
          stateType.push( cacheData.states[group][prop] )
        }
        return stateType
      }
    } else {
      return cacheData.states
    }
  },
  getTypes: (group, id) => {
    if( group !== undefined && cacheData.types[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.types[group][id] !== undefined ) ? cacheData.types[group][id] : { typ_name: 'Non définit', typ_tag: 'ND' };
      } else {
        let tabType = []
        for( let prop in cacheData.types[group] ){
          tabType.push( cacheData.types[group][prop] )
        }
        return tabType
        //return cacheData.types[group]
      }
    } else if( group !== undefined ){
      let tabType = []  
      return tabType
    } else {
      return cacheData.types
    }
  },
  getTypesByTag: (group, tag) => {
    let tabType = []
    if( group.length ){
      for( let prop in cacheData.types[group] ){
        if( cacheData.types[group][prop].typ_tag == tag ){
          tabType.push( prop )
        }
      }
    } else {
      for( let group in cacheData.types ){
        for( let prop in cacheData.types[group] ){
          if( cacheData.types[group][prop].typ_tag == tag ){
            tabType.push( prop )
          }
        }
      }
    }
    return tabType
  },
  getTypesByGroup: (group) => {
    let tabType = []
    let groupTab = group.split(",")
    groupTab.array.forEach(element => {
      for( let prop in cacheData.types[element] ){
          tabType.push( cacheData.types[element][prop] )
      }      
    })
    return tabType
  },
  getUser: () => {
    return cacheData.user
  },
  getUserRole: () => {
    return cacheData.user.usr_role
  },
  getFilter: () => {
    // default value
    return cacheData.filter
  },
  getFilterUrl: () => {
    let urlTask = ''
    for( var prop in cacheData.filter ){
        if( cacheData.filter[prop] !== null && cacheData.filter[prop] !== undefined && cacheData.filter[prop].length > 0 ){
            urlTask += (urlTask.lenght) ? '&' : ''
            urlTask += prop + '=' + cacheData.filter[prop]
        }
    }
    return urlTask
  },
  getFiltrerFromStorage(){
    if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
      let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
      return cache.filter || {}
    }
  },
  getSearchBar: () => {
    return cacheData.searchBar
  },
  getSearchBarItem: (item) => {
    if( cacheData.searchBar !== undefined ){
      return cacheData.searchBar[item]
    } else {
      return ''
    }
  },
  getBookmark: () => {
    return cacheData.bookmark
  },
  getTaskType: () => {
    return cacheData.taskType
  },
  getObjectType: () => {
    return cacheData.objectType
  },
  getTheme: () => {
    return cacheData.theme
  },
  getVersion: () => {
    return cacheData.version
  },
  getLists: () => {
    return cacheData.lists
  },
  getListsData: () => {
    return cacheData.listsData
  },
  getListsDataByLstId: () => {
    let list = {}
    cacheData.lists.forEach(element => {
      list[ element.lst_id ] = []
    })
    cacheData.listsData.forEach(element => {
      list[ element.lsd_lst_id ].push(element)
    });
    return list
  },
  getProjects: () => {
    return cacheData.projects
  },
}

export const cacheActions = {
  async initCacheFromStorage(){
    new Promise((resolve) => {
      if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
        let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
        cacheMutations.setStates( cache.states )
        cacheMutations.setTypes( cache.types )
        cacheMutations.setUser( cache.user || {} )
        cacheMutations.setFilter( cache.filter || {} )
        cacheMutations.setBookmark( cache.bookmark || {} )
        cacheMutations.setObjectType( cache.objectType || [] )
        cacheMutations.setTheme( cache.theme || [] )
        cacheMutations.setVersion( cache.version || [] )
        cacheMutations.setProjects( cache.projects || [] )
      }
      resolve('resolved')
    })
  },
  async storeCacheToStorage(){
    new Promise((resolve) => {
      Storages.localStorage.set( Vue.prototype.$APP_COD + "_cache", cacheData)
      resolve('resolved')
    })
  }
}

export const cacheMutations = {
  setStates( states ) {
    cacheData.states = states;
  },
  setTypes( types ) {
    cacheData.types = types;
  },
  setUser( user ) {
    cacheData.user = user;
  },
  setFilter( filter ) {
    cacheData.filter = filter;
  },
  setSearchBar( searchBar ) {
    cacheData.searchBar = searchBar;
  },
  setBookmark( bookmark ) {
    cacheData.bookmark = bookmark;
  },
  setSearchBarItem( item, value ) {
    cacheData.searchBar[item] = value;
  },
  setTaskType( taskType ) {
    cacheData.taskType = taskType;
  },
  setObjectType( objectType ) {
    cacheData.objectType = objectType;
  },
  setTheme( theme ) {
    cacheData.theme = theme;
  },
  setVersion( version ) {
    cacheData.version = version;
  },
  setLists( lists ) {
    cacheData.lists = lists;
  },
  setListsData( listsData ) {
    cacheData.listsData = listsData;
  },
  setProjects( projects ) {
    cacheData.projects = projects;
  },

}