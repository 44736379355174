<template>
<v-container fluid class="pa-0 ma-0">
    <v-toolbar class="pr-1 elevation-1" height="48px">
        <v-btn icon :href="btnBack"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche projet</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <!-- Composant -->
    <v-card fluid class="ma-2 pa-0 elevation-1">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Projets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined :href="'/#/projects/' + pro_id + '/edit'" color="success" depressed class="ma-0">
            <v-icon left dark>mdi-pencil</v-icon>
            <span class="hidden-sm-and-down">Modifier</span>
        </v-btn>
        <v-menu v-if="objProject.pro_id > 0" v-model="optionMenu" nudge-bottom="3" offset-y transition="slide-x-transition" left class="elevation-0">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn color="orange" @click="deleteProject('GEN04')" >
                <v-icon left>mdi-archive</v-icon>Archiver
            </v-btn><br />
            <v-btn color="error" @click="deleteProject()" style="cursor:pointer;">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
    </v-toolbar>
    <v-card-text>
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
        <v-list two-line>
            <v-list-item>
                <v-list-item-action><v-icon color="indigo">mdi-subtitles</v-icon></v-list-item-action>
                <v-list-item-content>
                <v-list-item-title>Nom</v-list-item-title>
                <v-list-item-subtitle>{{ objProject.pro_label }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action><v-icon color="indigo">mdi-message-bulleted</v-icon></v-list-item-action>
                <v-list-item-content>
                <v-list-item-title>Description</v-list-item-title>
                <v-list-item-subtitle>{{ objProject.pro_comment }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-list two-line>
                <v-list-item>
                    <v-list-item-action><v-icon color="indigo">mdi-calendar-range</v-icon></v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Début</v-list-item-title>
                    <v-list-item-subtitle>{{ locDateFormat.formatLocale(objProject.pro_date_cal, 'fr') }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action><v-icon color="indigo">mdi-projector-screen</v-icon></v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Projection</v-list-item-title>
                    <v-list-item-subtitle>
                        <v-chip small outlined label>{{ getCacheType('PRJ', objProject.pro_typ_id_projection).typ_name }}</v-chip>
                    </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-flex>
        </v-layout>
    </v-card-text>
    </v-card>
    <!-- Associative Tab -->
    <v-card fluid class="ma-2 pa-0 elevation-1">
        <v-divider></v-divider>
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item">{{ item }}</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <tab-version v-if="activeTab === 0 && showTab == true" :objProject="objProject" :toast="toast"></tab-version>
        <tab-event   v-if="activeTab === 1 && showTab == true" :xxx_id="pro_id" :table_id="'pro'" :title="'Evenement'" :toast="toast" :routeState="routeState"></tab-event>
        <tab-doc     v-if="activeTab === 2 && showTab == true" :doc_xxx_id="pro_id" :table_id="'pro'" :toast="toast" :routeState="routeState"></tab-doc>
        <tab-user    v-if="activeTab === 3 && showTab == true" :pro_id="pro_id" :xxx_id="pro_id" :table_id="'pro'" :toast="toast"></tab-user>
        <tabTool     v-if="activeTab === 4 && showTab == true" :pro_id="pro_id" :xxx_id="pro_id" :table_id="'pro'" :toast="toast" />
        <tab-filter  v-if="activeTab === 5 && showTab == true" :pro_id="pro_id" :xxx_id="pro_id" :table_id="'pro'" :toast="toast"></tab-filter>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { proObj } from '../mixins/pro_obj.js'
import versionTab   from '../components/Tab_version.vue'
import eventTab     from '../components/Tab_event.vue'
import documentTab  from '../components/Tab_document.vue'
import layerFilter  from '../components/Tab_layerfilter.vue'
import userTab      from '../components/Tab_user.vue'
import tabTool      from '@/components/Tab_tools'

import { cacheGetters } from "../store/cache.module"

export default {
    name: 'projectview',
    props: ['toast'],
    mixins: [ proObj ],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            activeTab: 0, showTab: true,
            tabItems: [ 'Version', 'evenements', 'fichiers', 'Utilisateurs', 'Outils' ],
            optionMenu: false,
            routeState: { view: false, edit: true }
        }
    },
    computed: {
        btnBack : function(){
            if( this.pro_id > 0 && this.$route.params.mode === 'edit'){
                return '/#/projects/' + this.pro_id
            } else {
                return '/#/projects'
            }
        },
        pro_id : function(){
            return this.$route.params.pro_id
        },
    },
    beforeMount: function(){
        if( this.$route.params.pro_id == undefined || this.$route.params.pro_id == 0 ){
            this.$router.push({ path: '/projects/' })
            //this.pro_id = this.$route.params.pro_id
        } else {
            this.objProject.pro_id = this.$route.params.pro_id
        }
    },
    mounted:function (){
        if( this.$route.params.pro_id !== undefined || this.$route.params.pro_id > 0 ){
            this.getProjectShow()
        }
    },
    methods: {
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { 
        'tab-version' : versionTab,
        'tab-event' : eventTab,
        'tab-doc' : documentTab,
        'tab-user' : userTab,
        'tab-filter': layerFilter,
        tabTool
    }
}
</script>