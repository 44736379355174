import { scopeCredential } from "./scopeCredential"

const ChMain         = () => import(/* webpackChunkName: "build-glob" */ './layouts/Main.vue') 
const ChDasboard     = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard.vue')
const ChMap          = () => import(/* webpackChunkName: "build-map" */ './pages/Map.vue')
const ChDocument     = () => import(/* webpackChunkName: "build-doc" */ './pages/Document.vue')
const ChEvent        = () => import(/* webpackChunkName: "build-doc" */ './pages/Event.vue')
const ChSlider       = () => import(/* webpackChunkName: "build-glob" */ './components/Slider.vue')
//const ChViewCard     = () => import(/* webpackChunkName: "build-glob" */ './components/View_card.vue')
const ChEditCard     = () => import(/* webpackChunkName: "build-glob" */ './components/Edit_card.vue')
//const ChViewAction     = () => import(/* webpackChunkName: "build-glob" */./components/View_card.vueue')

export default {
menu : [
    { 'href': '/dashboard',  'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER', 'AGENT',] },
    { 'href': '/map',        'title': 'Carte', 'icon': 'mdi-map', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER', 'AGENT'] },
    { 'href': '/planning',   'title': 'Planning', 'icon': 'mdi-calendar-multiselect', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER', 'AGENT'] },
    { 'href': '/events',     'title': 'Evenements', 'icon': 'mdi-calendar-text', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    //{ 'href': '/documents',  'title': 'Documents', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER', 'AGENT'] },
    { divider: true },
    { 'header': 'Reporting', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/reports', 'title': 'Export', 'icon': 'mdi-file-chart', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'] },
    { divider: true },
    { 'header': 'Paramètres',   'title': 'Paramètres', 'icon': 'mdi-cogs', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'PARTE_USER'], items: [
        { 'href': '/projects',  'title': 'Entités', 'icon': 'mdi-folder-multiple', 'role': ['ADMIN_USER'] },
        { 'href': '/themes',     'title': 'Modèles', 'icon': 'mdi-sitemap', 'role': ['ADMIN_USER'] },
        { 'href': '/lists',      'title': 'Listes', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER', 'SUPER_USER'] },
        //{ 'href': '/block',     'title': 'Blocks', 'icon': 'mdi-drawing-box', 'role': ['ADMIN_USER'] },
        { 'href': '/contacts',  'title': 'Gestion des contacts', 'icon': 'mdi-account-box-multiple', 'role': scopeCredential.contacts.read },
        { 'href': '/equipments', 'title': 'Materiel', 'icon': 'mdi-tools', 'role': ['ADMIN_USER', 'SUPER_USER'] },
        { 'href': '/states',    'title': 'Gestion états', 'icon': 'mdi-tag-multiple', 'role': ['ADMIN_USER'] },
        { 'href': '/types',     'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER'] },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
//    { 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    { 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench', 'role': ['ADMIN_USER', 'SUPER_USER'] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'setpass', path: '/auth/set-password/:token',   component: require('./layouts/Password.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'loginsso', path: '/login/:authcode',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                    { name: 'dash_slide_prev', path: 'sheet/:cad_uid', meta: { component: 'cardView' }, components: { content: ChEditCard } },
                    { name: 'dash_slide_edit', path: 'sheet/:cad_uid/layer/:lra_id/type/:typ_id', components: { content: ChEditCard } },
                  //{ name: 'dash_slide_view', path: 'sheet/:cad_uid/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                    { name: 'map_slide_prev', path: 'center/:latlng' },
                    { name: 'map_slide_prev', path: 'sheet/:cad_uid', meta: { component: 'cardView' }, components: { content: ChEditCard } },
                    { name: 'map_slide_edit', path: 'sheet/:cad_uid/layer/:lra_id/type/:typ_id', components: { content: ChEditCard } },
                  //{ name: 'map_slide_view', path: 'sheet/:cad_uid/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'event', path: '/events', 
                components: { default: ChEvent, modal: ChSlider },
                children: [
                    { name: 'eve_slide_prev', path: 'sheet/:cad_uid', meta: { component: 'cardView' }, components: { content: ChEditCard } },
                  //{ name: 'eve_slide_edit', path: 'sheet/:cad_uid/modifier', components: { content: ChViewAction } },
                  //{ name: 'eve_slide_view', path: 'sheet/:cad_uid/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'document', path: '/documents', 
                components: { default: ChDocument, modal: ChSlider },
                children: [
                    { name: 'doc_slide_prev', path: 'sheet/:cad_uid', meta: { component: 'cardView' }, components: { content: ChEditCard } },
                  //{ name: 'doc_slide_edit', path: 'sheet/:cad_uid/modifier', components: { content: ChViewAction } },
                  //{ name: 'doc_slide_view', path: 'sheet/:cad_uid/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'planning',      path: '/planning', component: require('./pages/Planning.vue').default },
            { name: 'report',        path: '/reports', component: require('./pages/Report.vue').default },
            
            { name: 'projects',      path: '/projects',               component: require('./pages/Project.vue').default },
            { name: 'projectview',   path: '/projects/:pro_id/view',  component: require('./pages/Project_view.vue').default },
            { name: 'projectedit',   path: '/projects/:pro_id/edit',  component: require('./pages/Project_edit.vue').default },
            { name: 'themes',        path: '/themes',                 component: require('./pages/Theme.vue').default },
            { name: 'themesedit',    path: '/themes/:tem_id',         component: require('./pages/Theme_edit.vue').default },
            { name: 'lists',         path: '/lists',                 component: require('./pages/List.vue').default },
            { name: 'listsedit',     path: '/lists/:lst_id',         component: require('./pages/List_edit.vue').default },
            { name: 'objecttypeedit',path: '/themes/:tem_id/objecttype/:obt_id', component: require('./pages/Objecttype_edit.vue').default },
            { name: 'equipments',    path: '/equipments',             component: require('./pages/Equipment.vue').default },
            { name: 'equipmentedit', path: '/equipments/:equ_id',     component: require('./pages/Equipment_edit.vue').default },

            { name: 'states',        path: '/states',            component: require('./pages/States.vue').default },
            { name: 'statesedit',    path: '/states/:sta_id',    component: require('./pages/States_edit.vue').default },
            { name: 'types',         path: '/types',             component: require('./pages/Types.vue').default },
            { name: 'typesedit',     path: '/types/:typ_id',     component: require('./pages/Type_edit.vue').default },
            { name: 'contacts',      path: '/contacts',          component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view', path: '/contacts/:ctt_id',  component: require('./pages/Contacts_edit.vue').default },
            
            { name: 'users',         path: '/users',             component: require('./pages/Users.vue').default },
            { name: 'useredit',      path: '/users/:usr_id',     component: require('./pages/User_edit.vue').default },
            
            //{ name: 'importdata',   path: '/importdata', component: require('./pages/ImportData.vue').default },
            { name: 'jobs',         path: '/jobs', component: require('./pages/BackgroundJobs.vue').default },
            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}